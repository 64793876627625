import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { DEFAULT_LOCALE, navigatorLocalesMap } from '@constants/locale';
import { selectLanguage } from '@services/language/selectors';
import enTranslationMessages from 'src/translations/en-US/index.json';

export const getTranslationMessages = async locale => import(`@translations/${locale}/index.json`);

export function LanguageProvider({ children }) {
    const [messages, setMessages] = useState(enTranslationMessages);
    const [includedLocale, setIncludedLocale] = useState(DEFAULT_LOCALE);
    const userLocale = useSelector(selectLanguage);
    const locale = useMemo(() => navigatorLocalesMap[userLocale] || userLocale, [userLocale]);

    useEffect(() => {
        getTranslationMessages(locale)
            .then(module => {
                if (module?.default) {
                    setMessages(module.default);
                    setIncludedLocale(locale);
                }
            })
            .catch(() => {});
    }, [locale]);

    return (
        <IntlProvider locale={includedLocale} key={includedLocale} messages={messages} defaultLocale={DEFAULT_LOCALE}>
            {React.Children.only(children)}
        </IntlProvider>
    );
}

LanguageProvider.propTypes = {
    locale: PropTypes.string,
    messages: PropTypes.object,
    children: PropTypes.element.isRequired,
};

export default LanguageProvider;
