import moment from 'moment';
import momentTimezone from 'moment-timezone';
import 'moment/min/locales';
import { LOCALE } from '@constants';
import { CUSTOM_FORMATS, MOMENT_FORMATS } from '@constants/dateAndTime';
import { DEFAULT_LOCALE, momentLocalesMap, navigatorLocalesMap } from '@constants/locale';
import { getItem, setItem } from '@utils/storageUtils';

const timeZone = momentTimezone.tz.guess();

export const setMomentLocale = lang => {
    let locale = lang || getItem(LOCALE);
    locale = navigatorLocalesMap[locale] || locale;
    const preparedLocale = momentLocalesMap[locale] || DEFAULT_LOCALE;
    moment.locale(preparedLocale);
    setItem([LOCALE], [locale]);
};

export const toApiFormat = date => moment.utc(date).format();

export const toFormat = (date, format) =>
    moment(date)
        .tz(timeZone)
        .format(format);

export const localizedDateWithoutYear = date => {
    const string = moment(date).format('L');
    const pattern = /((\/|.|^)\d{4})|(\d{4}(.|\/))/;
    return string.replace(pattern, '');
};

export const toFormatMoment = (date, format) => moment(date, format).tz(timeZone);

export const getDuration = seconds =>
    moment()
        .set({ hours: 0, minutes: 0, seconds: 0 })
        .add(seconds, 'seconds')
        .format('HH:mm');

export const formats = MOMENT_FORMATS;

export const toLocaleFormat = (date, format) => toFormat(date, format || formats.dateByLocale).replace(/\//g, '.');

export const getDurationAndUnit = value => {
    const valueDuration = toFormatMoment(value, 'hh:mm:ss');
    const durationValue = valueDuration.hours() || valueDuration.minutes();
    const durationUnitId = valueDuration.hours() ? 'UNIT_HOURS' : 'UNIT_MINUTES';
    return { duration: durationValue, unitId: durationUnitId };
};

export const getCustomFormat = function(format) {
    const lc = getItem(LOCALE);
    return (CUSTOM_FORMATS[lc] && CUSTOM_FORMATS[lc][format]) || format;
};
