export const supportedLocales = [
    { value: 'en-US', label: 'LOCALE_EN_US' },
    { value: 'en-GB', label: 'LOCALE_EN_GB' },
    { value: 'de-CH', label: 'LOCALE_DE_CH' },
    { value: 'it-CH', label: 'LOCALE_IT_CH' },
    { value: 'ja-JP', label: 'LOCALE_JA_JP' },
    { value: 'ko-KR', label: 'LOCALE_KO_KR' },
    { value: 'ru-RU', label: 'LOCALE_RU_RU' },
    { value: 'fr-CH', label: 'LOCALE_FR_CH' },
    { value: 'fr-FR', label: 'LOCALE_FR_CH' },
    { value: 'fr-CA', label: 'LOCALE_FR_CA' },
    { value: 'pl-PL', label: 'LOCALE_PL_PL' },
    { value: 'pt-BR', label: 'LOCALE_PT_BR' },
    { value: 'pt-PT', label: 'LOCALE_PT_PT' },
    { value: 'es-ES', label: 'LOCALE_ES_ES' },
    { value: 'nl-NL', label: 'LOCALE_NL_NL' },
    { value: 'zh-CN', label: 'LOCALE_ZH_CN' },
    { value: 'zh-HK', label: 'LOCALE_ZH_HK' },
    { value: 'th-TH', label: 'LOCALE_TH_TH' },
    { value: 'da-DK', label: 'LOCALE_DA_DK' },
    { value: 'id-ID', label: 'LOCALE_ID_ID' },
    { value: 'my-MM', label: 'LOCALE_MY_MM' },
    { value: 'el-GR', label: 'LOCALE_EL_GR' },
];

export const DEFAULT_LOCALE = 'en-US';

export const momentLocalesMap = {
    'en-US': 'en-us',
    'en-GB': 'en-gb',
    'da-DK': 'da',
    'de-CH': 'de-ch',
    'it-CH': 'it',
    'ja-JP': 'ja',
    'ko-KR': 'ko',
    'ru-RU': 'ru',
    'fr-CH': 'fr-ch',
    'fr-FR': 'fr-fr',
    'fr-CA': 'fr-ca',
    'pl-PL': 'pl',
    'pt-BR': 'pt-br',
    'pt-PT': 'pt',
    'es-ES': 'es',
    'nl-NL': 'nl',
    'zh-CN': 'zh-cn',
    'zh-HK': 'zh-hk',
    'th-TH': 'th',
    'el-GR': 'el',
};

export const navigatorLocalesMap = {
    en: 'en-US',
    da: 'da-DK',
    de: 'de-CH',
    'de-DE': 'de-CH',
    it: 'it-CH',
    ja: 'ja-JP',
    ko: 'ko-KR',
    ru: 'ru-RU',
    'it-CH': 'it-CH',
    'fr-CA': 'fr-CA',
    'fr-CH': 'fr-CH',
    'fr-FR': 'fr-FR',
    pl: 'pl-PL',
    'pt-PT': 'pt-PT',
    'pt-BR': 'pt-BR',
    es: 'es-ES',
    nl: 'nl-NL',
    'zh-HK': 'zh-HK',
    zh: 'zh-CN',
    th: 'th-TH',
};

export const countriesLangMap = {
    'en-US': 'en',
    'en-GB': 'en',
    'da-DK': 'da',
    'de-CH': 'de',
    'it-CH': 'it',
    'ja-JP': 'ja',
    'ko-KR': 'ko',
    'ru-RU': 'ru',
    'fr-CH': 'fr',
    'fr-CA': 'fr',
    'pl-PL': 'pl',
    'pt-BR': 'pt',
    'pt-PT': 'pt',
    'es-ES': 'es',
    'nl-NL': 'nl',
    'zh-CN': 'zh',
    'zh-HK': 'zh',
    'th-TH': 'th',
};
